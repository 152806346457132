<template>
  <div>
    <icon-reparation
      v-if="isPrestation('REPARATION')"
      style="font-size: 50px"
      :color="isSelectionne ? 'white' : 'var(--theme-icone)'"
    />
    <icon-controle-technique
      v-if="isPrestation('CONTROLE_TECHNIQUE')"
      style="font-size: 50px"
      :color="isSelectionne ? 'white' : 'var(--theme-icone)'"
    />
    <icon-carrosserie
      v-if="isPrestation('CARROSSERIE')"
      style="font-size: 50px"
      :color="isSelectionne ? 'white' : 'var(--theme-icone)'"
    />
    <icon-pneus
      v-if="isPrestation('PNEUMATIQUE')"
      style="font-size: 50px"
      :color="isSelectionne ? 'white' : 'var(--theme-icone)'"
    />
    <icon-vitrage
      v-if="isPrestation('VITRAGE')"
      style="font-size: 50px"
      :color="isSelectionne ? 'white' : 'var(--theme-icone)'"
    />
    <icon-revision
      v-if="isPrestation('REVISION')"
      style="font-size: 50px"
      :color="isSelectionne ? 'white' : 'var(--theme-icone)'"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  prestation: string;
  isSelectionne: boolean;
}>();

const isPrestation = (prestation: string) => props.prestation === prestation;
</script>
<style>
.fill-primary {
  fill: var(--theme-icone);
}
</style>
